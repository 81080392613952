import { useEffect } from "react"

// styles
import "../../Styles/AppLayout.scss"

//imgs
import logotipo from "../../Images/Brand.png";

export default function({title, children}) {
  useEffect(() => {

  }, [])


  return (<section className="app-page-wrap-container">
    <div className="app-page-container">
      <div style={{height: "15px"}} />

      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <img src={logotipo} className="appPageLogo" />

        <h1 className="h1" style={{textAlign: "center"}}>
          {title}
        </h1>
      </div>

      <div style={{height: "55px"}} />

      {children}
    </div>
  </section>)
}